import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { filter, forEach, includes, cloneDeep, map } from "lodash";

//components
import { ButtonProgress } from "components/Button";
import { DialogWrapper } from "components/Dialogs";
import { Capitalized } from "helpers/textHelpers";
import MuiTypography from "@mui/material/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//material-ui
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell";
function BatchReaddDialog({ cardIdList, handleClose }) {
  const ItemTableCell = withStyles((theme) => ({
    arrow: { color: "#e9e9e9" },
    tooltip: {
      backgroundColor: "#e9e9e9",
      maxWidth: 450,
      border: "1px solid #fff",
      padding: theme.spacing(2),
      borderRadius: 4,
      color: "#333",
      fontSize: "14px",
    },
  }))(MuiTableCell);
  const firestore = useFirestore();
  const snackbar = useSnackbar();

  const [submitting, setSubmitting] = useState(false);

  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);

  const cardSelector = useSelector(
    (state) => state.firestore.data.visitor_cards ?? []
  );
  const cardArray = Object.keys(cardSelector).map((key) => ({
    id: key,
    ...cardSelector[key],
  }));

  const filteredCards = filter(cardArray, (card) =>
    includes(cardIdList, card.id)
  );

  const handleBatchReadd = async () => {
    setSubmitting(true);

    const batches = [firestore.batch()];
    const profileRef = firestore.collection("ac_profiles");
    const auditRef = firestore.collection("ac_audit_trails");

    const writeLimit = 200;
    let operationCount = 0;
    const cardArr = Object.keys(cardSelector).map((key) => ({
      id: key,
      ...cardSelector[key],
    }));

    try {
      const filteredCards = filter(cardArr, (card) =>
        includes(cardIdList, card.id)
      );

      forEach(filteredCards, (card) => {
        console.log(card);
        const oldValue = cloneDeep(card);
        if (!!card && card.status !== "deleted") {
          batches[batches.length - 1].update(profileRef.doc(card.id), {
            status: card.status,
            updated_at: firestore.FieldValue.serverTimestamp(),
            action: "add",
            attempted: 0,
            is_sync: false,
          });
          batches[batches.length - 1].set(auditRef.doc(), {
            action: "Re-Add Card",
            created_at: firestore.FieldValue.serverTimestamp(),
            created_by: {
              user_id: auth.uid,
              name: profile.name,
            },
            description: `${Capitalized(profile.name)} re-added visitor card ${
              card.identity_number
            }`,
            module: "access_card",
            profile: {
              id: card.id,
              identity_number: card.identity_number,
            },
            values: oldValue,
          });
        }

        batches.push(firestore.batch());
      });

      await Promise.all(batches.map((batch) => batch.commit()));

      snackbar.enqueueSnackbar("Cards Re-Add successfully", {
        variant: "success",
      });

      handleClose();
    } catch (error) {
      setSubmitting(false);
      snackbar.enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const transactionTable = (items) => (
    <Grid mt={1} container spacing={2}>
      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ borderBottom: `2px solid #1F4E23` }}>
              <ItemTableCell width="20%">Block </ItemTableCell>
              <ItemTableCell>Unit</ItemTableCell>
              <ItemTableCell width="20%">Card Name</ItemTableCell>
              <ItemTableCell>Card Number</ItemTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(items, (item) => (
              <TableRow key={item.id}>
                {console.log(item.id)}
                <ItemTableCell>{item.unit.block_name}</ItemTableCell>
                <ItemTableCell>{item.unit.unit_name}</ItemTableCell>
                <ItemTableCell>{item.profile_name}</ItemTableCell>
                <ItemTableCell>{item.identity_number}</ItemTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );

  return (
    <DialogWrapper open={true} size="md">
      <DialogTitle>
        <b>Card Status Update</b>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Re-Add selected Cards? Action cannot be undone.
        </Typography>
        {transactionTable(filteredCards)}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="contained"
        >
          Cancel
        </Button>
        <ButtonProgress
          margin={false}
          type="submit"
          onClick={handleBatchReadd}
          loading={submitting}
          containName={"OK"}
        />
      </DialogActions>
    </DialogWrapper>
  );
}

export default React.memo(BatchReaddDialog);
