import React, { useMemo, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import { filter, includes, orderBy, map } from "lodash";
import {
  toggleEditResidentDialogOpen,
  toggleEditVisitorDialogOpen,
} from "stores/card/CardAction";
import { closeDrawer } from "stores/main/MainActions";
import { statusMap } from "utility/constants";
// components (global)
import {
  CustomerDrawer,
  Paginate,
  BottomDrawerButton,
  ChipColor,
  TrueFalseWithDot,
} from "components";

// components (local)
import {
  AddVisitor,
  EditVisitorDialog,
  FilterDrawer,
  VisitorCardDetails,
  VisitorCard,
  VisitorSkeleton,
  BatchReaddDialog,
} from "./components";

import {
  usePopupState,
  bindTrigger,
  bindPopover,
  bindMenu,
} from "material-ui-popup-state/hooks";

// firebase
import { isLoaded } from "react-redux-firebase";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// material-ui
import {
  FilterList as FilterListIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from "@material-ui/icons";

import {
  Button,
  Grid,
  Hidden,
  Chip,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Sorting,
  FilterRow,
  Selection,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";
// stores
import { openDrawer } from "stores/main/MainActions";
import BatchDeleteDialog from "./components/BatchDeleteDialog";
import BatchClearCountDialog from "./components/BatchClearCountDialog";

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  link: {
    marginRight: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  search: { width: "100%", marginBottom: theme.spacing(2) },
  paginate: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  title: {
    position: "relative",
    "&:after": {
      position: "absolute",
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main,
    },
  },
  green: { color: "#038c00" },
  grey: { color: "#979797" },
  red: { color: "#ad2424" },
}));

const Visitor = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const exporterRef = useRef(null);
  // variable
  const perPage = 12;

  const [showExportButtons, setShowExportButtons] = useState(false);
  function onSelectionChanged({ selectedRowsData }) {
    setShowExportButtons(selectedRowsData.length > 0);
    // dynamically set height
  }
  const [selectedCardIds, setSelectedCardIds] = useState([]);
  const handleCloseDialog = useCallback(() => {
    setSelectedCardIds([]);
    exporterRef.current.instance.deselectAll();
  }, []);

  const [search, setSearch] = useState({
    card_type: "",
    card_status: "",
    identity_number: "",
    profile_code: "",
    profile_name: "",
    vehicle_no: "",
    unit_id: "",
    is_suspend: "",
  });
  const [showBatchDeleteDialog, setShowBatchDeleteDialog] = useState(false);
  const [showBatchReaddDialog, setShowBatchReaddDialog] = useState(false);
  const [showBatchClearCountDialog, setShowBatchClearCountDialog] =
    useState(false);
  const [toggle, setToggle] = useState("module");
  const handleChange = (event, nextView) => {
    setToggle(nextView);
    setSearch({
      ...search,
      identity_number: "",
    });
  };

  // selector
  const cardSelector = useSelector(
    (state) => state.firestore.data.visitor_cards
  );
  const editDialog = useSelector((state) => state.card.edit_visitor_dialog);

  // state

  const [offset, setOffset] = useState(0);

  // memo
  const cardsSearch = useMemo(() => {
    setOffset(0);

    // Mapped key into array
    let cardMapped = [];
    if (cardSelector) {
      cardMapped = Object.keys(cardSelector).map((key) => {
        return { ...cardSelector[key], id: key };
      });
    }

    return orderBy(
      filter(cardMapped, (card) => {
        const unitId = card.unit
          ? card.unit.unit_id.toLowerCase()
          : "no_unit_assign";

        return (
          includes(
            card.card_type?.toString().toLowerCase(),
            search.card_type?.toLowerCase()
          ) &&
          includes(
            card.identity_number?.toString().toLowerCase(),
            search.identity_number.toString().toLowerCase()
          ) &&
          includes(
            card.status.toLowerCase(),
            search.card_status?.toLowerCase()
          ) &&
          includes(
            card.profile_code.toLowerCase(),
            search.profile_code.toLowerCase()
          ) &&
          includes(
            card.profile_name.toLowerCase(),
            search.profile_name.toLowerCase()
          )
        );
      }),
      ["profile_code", "status"]
    );
  }, [cardSelector, search]);

  const pageCount = useMemo(
    () => Math.ceil(cardsSearch.length / perPage),
    [cardsSearch]
  );

  const cards = useMemo(
    () => cardsSearch.slice(offset, offset + perPage),
    [cardsSearch, offset]
  );

  const cardCsvData = useMemo(
    () =>
      cardsSearch.map((card) => {
        const block_date = card.blocked_settings?.enable_date;

        return {
          block_name: card.unit?.block_name ?? "",
          unit_name: card.unit?.unit_name ?? "",
          card_type: card.card_type,
          card_no: card.identity_number,
          staff_no: card.profile_code ?? "",
          sticker_no: card.sticker_serial_no ?? "",
          profile_name: card.profile_name ?? "",
          phone_number: card.contact_number ?? "",
          car_plate: card.vehicle_info?.number ?? "",
          parking_lot_no: card.parking_lot_no ?? "",
          anti_passback: !!card.anti_passback ? "yes" : "no",
          lift_card: !!card.is_lift ? "yes" : "no",
          status: card.status,
          created_by: card.created_by.name ?? "",
          block_date:
            card.blocked_settings?.enable === true && block_date !== undefined
              ? moment.unix(block_date.seconds).format("DD/MM/YYYY h:mm a")
              : "",
        };
      }),
    [cardsSearch]
  );

  const cardListData = useMemo(
    () =>
      cardsSearch.map((card) => {
        const block_date = card.blocked_settings?.enable_date;

        return {
          id: card.id,

          block_name: card.unit?.block_name ?? "",
          unit_name: card.unit?.unit_name ?? "",
          card_type: card.card_type,
          card_no: card.identity_number,
          staff_no: card.profile_code ?? "",
          is_assign: !!card.is_assign ? "Yes" : "No",
          profile_name: card.profile_name ?? "",
          attempted: card.attempted.toString() ?? "",
          lift_card: !!card.is_lift ? "Yes" : "No",
          anti_passback: !!card.anti_passback ? "Yes" : "No",
          status: card.status,
          created_by: card.created_by.name ?? "",
          created_at: moment.unix(card.created_at.seconds).format("DD/MM/YYYY"),

          updated_at: moment
            .unix(card.updated_at?.seconds ?? card.created_at.seconds)
            .format("DD/MM/YYYY"),
        };
      }),
    [cardsSearch]
  );
  // functions
  const handleFilter = () => {
    dispatch(openDrawer({ toggle: "visitor_card_filter" }));
  };

  const handleSearchChange = (event) => {
    setSearch({
      ...search,
      identity_number: event.target.value,
    });
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * perPage);
    setOffset(offset);
  };

  const handleFilterSubmit = (value) => {
    setSearch({
      ...search,
      card_type: value.cardType ? value.cardType.id : "",
      card_status: value.cardStatus
        ? value.cardStatus.id !== "suspend"
          ? value.cardStatus.id
          : ""
        : "",
      identity_number: value.identityNumber,
      profile_code: value.profileCode,
      profile_name: value.profileName,
      unit_id: value.unit ? value.unit.id : "",
      is_suspend: value.cardStatus ? value.cardStatus?.id === "suspend" : "",
    });
  };
  function batchReadd() {
    const dataGrid = exporterRef.current.instance;
    const selectedItems = dataGrid.getSelectedRowKeys();
    setShowBatchReaddDialog(true);
    setSelectedCardIds(map(selectedItems, (item) => item.id));
    console.log(selectedItems);
  }
  function batchDelete() {
    const dataGrid = exporterRef.current.instance;
    const selectedItems = dataGrid.getSelectedRowKeys();
    setShowBatchDeleteDialog(true);
    setSelectedCardIds(map(selectedItems, (item) => item.id));
    console.log(selectedItems);
  }
  function batchClearCount() {
    const dataGrid = exporterRef.current.instance;
    const selectedItems = dataGrid.getSelectedRowKeys();
    setShowBatchClearCountDialog(true);
    setSelectedCardIds(map(selectedItems, (item) => item.id));
    console.log(selectedItems);
  }

  const statusCell = (cellData) => (
    <ChipColor
      chipColor={
        cellData.value === "active"
          ? "green"
          : cellData.value === "deleted"
          ? "grey"
          : cellData.value === "inactive" ||
            cellData.data.blocked_settings.enable
          ? "red"
          : "green"
      }
      colorIndex={800}
      label={cellData.value}
      size="small"
    />
  );

  const unitCell = (cellData) => (
    <ChipColor
      chipColor={
        cellData.data.status === "active"
          ? "green"
          : cellData.data.status === "deleted"
          ? "grey"
          : cellData.data.status === "inactive" ||
            cellData.data.blocked_settings.enable
          ? "red"
          : "green"
      }
      colorIndex={800}
      label={cellData.data.is_assign ? cellData.value : "-"}
      size="small"
    />
  );

  return (
    <div className={classes.root}>
      {editDialog.open && <EditVisitorDialog />}
      <Grid direction="row" alignItems="center" container justify="center">
        <Grid item md={8}>
          <ToggleButtonGroup
            orientation="horizontal"
            exclusive
            value={toggle}
            xs={12}
            onChange={handleChange}
          >
            <ToggleButton value="module" aria-label="list">
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid
          item
          container
          md={4}
          alignItems="center"
          justify="flex-end"
          spacing={2}
        >
          <Hidden smUp>
            <Grid
              item
              container
              xs={10}
              md={2}
              alignItems="center"
              justify="flex-end"
            ></Grid>
            <Grid
              item
              container
              md={2}
              xs={8}
              alignItems="center"
              justify="flex-end"
            ></Grid>
            <Grid item container xs={8} alignItems="center" justify="flex-end">
              <AddVisitor />
            </Grid>
          </Hidden>

          <Hidden xsDown>
            <Grid
              item
              container
              md={12}
              xs={8}
              alignItems="center"
              justify="flex-end"
            >
              <Grid
                item
                container
                md={2}
                xs={8}
                alignItems="center"
                justify="flex-end"
              ></Grid>

              <AddVisitor />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      {toggle === "module" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.header}
        >
          <Grid item md={11} xs={12}>
            <Paper>
              <TextField
                onChange={handleSearchChange}
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Card Number…"
              />
            </Paper>
          </Grid>
          <Grid item container md={1} justify="center" alignItems="center">
            <Tooltip title="More Filter">
              <IconButton onClick={handleFilter}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.header}
        >
          <Grid item md={11} xs={12}></Grid>

          <Grid
            item
            container
            md={1}
            justify="center"
            alignItems="center"
          ></Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item md={12}>
          <Typography className={classes.title} variant="h5">
            {`Showing ${cardsSearch.length} cards`}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.content}>
        <CustomerDrawer uniqName="view_visitor_card" size={2}>
          <VisitorCardDetails />
        </CustomerDrawer>

        <CustomerDrawer uniqName="visitor_card_filter">
          <FilterDrawer search={search} onSubmit={handleFilterSubmit} />
        </CustomerDrawer>

        {!isLoaded(cardSelector) ? (
          <VisitorSkeleton />
        ) : toggle === "module" ? (
          cards.length > 0 && (
            <React.Fragment>
              <Grid container>
                {cards.map((card) => (
                  <VisitorCard key={card.id} card={card} />
                ))}
              </Grid>

              <div className={classes.paginate}>
                <Paginate
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                />
              </div>
            </React.Fragment>
          )
        ) : (
          <React.Fragment>
            <Paper>
              <DataGrid
                allowColumnReordering={true}
                columnAutoWidth={true}
                dataSource={cardListData}
                // onRowDblClick={onRowDblClick}
                ref={exporterRef}
                repaintChangesOnly={true}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                showBorders={false}
                showColumnLines={false}
              >
                <ColumnFixing enabled={true} />
                <FilterRow visible={true} />
                <Sorting mode="multiple" />
                <Selection mode="multiple" showCheckBoxesMode="onClick" />
                <Column
                  caption="Block Name"
                  dataField="block_name"
                  cellRender={(cellData) =>
                    cellData.data.is_assign === "Yes" ? (
                      <TrueFalseWithDot
                        dotBoolean={true}
                        text={cellData.value}
                      />
                    ) : (
                      <TrueFalseWithDot dotBoolean={false} text="-" />
                    )
                  }
                />
                <Column
                  caption="Unit Name"
                  dataField="unit_name"
                  cellRender={(cellData) =>
                    cellData.data.is_assign === "Yes" ? (
                      <TrueFalseWithDot
                        dotBoolean={true}
                        text={cellData.value}
                      />
                    ) : (
                      <TrueFalseWithDot dotBoolean={false} text="-" />
                    )
                  }
                />

                <Column caption="Card Name" dataField="profile_name" />
                <Column caption="Card No." dataField="card_no" />
                <Column
                  caption="Is Assigned"
                  dataField="is_assign"
                  cellRender={(cellData) =>
                    cellData.data.is_assign === "Yes" ? (
                      <TrueFalseWithDot dotBoolean={true} text="Yes" />
                    ) : (
                      <TrueFalseWithDot dotBoolean={false} text="No" />
                    )
                  }
                />
                <Column
                  caption="Anti Passback"
                  dataField="anti_passback"
                  cellRender={(cellData) =>
                    cellData.data.anti_passback === "Yes" ? (
                      <TrueFalseWithDot dotBoolean={true} text="Yes" />
                    ) : (
                      <TrueFalseWithDot dotBoolean={false} text="No" />
                    )
                  }
                />
                <Column
                  caption="Status"
                  dataField="status"
                  cellRender={statusCell}
                />
                <Column
                  caption="Lift Card"
                  dataField="lift_card"
                  cellRender={(cellData) =>
                    cellData.data.lift_card === "Yes" ? (
                      <TrueFalseWithDot dotBoolean={true} text="Yes" />
                    ) : (
                      <TrueFalseWithDot dotBoolean={false} text="No" />
                    )
                  }
                />
                <Column caption="Attempted Counts" dataField="attempted" />
                <Column caption="Created At" dataField="created_at" />
                <Column caption="Last Updated At" dataField="updated_at" />
                <Column
                  fixed={true}
                  fixedPosition="right"
                  allowGrouping={false}
                  allowExporting={false}
                  allowFiltering={false}
                  allowSorting={false}
                  allowHiding={false}
                  allowColumnReordering={false}
                  allowFixing={false}
                  width={50}
                  cellRender={(cellData) => (
                    <MenuButton cardData={cellData.data} />
                  )}
                />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                  allowedPageSizes={[10, 20, 100]}
                />
              </DataGrid>
            </Paper>
            <BottomDrawerButton
              open={showExportButtons}
              buttons={[
                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={batchReadd}
                >
                  Re-Add
                </Fab>,
                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={batchDelete}
                >
                  Delete
                </Fab>,
                <Fab
                  variant="extended"
                  color="primary"
                  siz="small"
                  onClick={batchClearCount}
                >
                  Clear Counts
                </Fab>,
              ]}
            />
            {showBatchDeleteDialog && selectedCardIds.length > 0 && (
              <BatchDeleteDialog
                cardIdList={selectedCardIds}
                handleClose={handleCloseDialog}
              />
            )}
            {showBatchClearCountDialog && selectedCardIds.length > 0 && (
              <BatchClearCountDialog
                cardIdList={selectedCardIds}
                handleClose={handleCloseDialog}
              />
            )}
            {showBatchReaddDialog && selectedCardIds.length > 0 && (
              <BatchReaddDialog
                cardIdList={selectedCardIds}
                handleClose={handleCloseDialog}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const MenuButton = ({ cardData }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "popupMenu",
  });
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeDrawer());
  };

  const handleCardDetails = () => {
    popupState.close();
    dispatch(
      openDrawer({ toggle: "view_visitor_card", parameterId: cardData.id })
    );
  };
  const handleEditCard = () => {
    dispatch(toggleEditVisitorDialogOpen({ open: true, id: cardData.id }));
    onClose();
  };

  return (
    <>
      <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreHorizIcon fontSize="small" color="primary" />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem onClick={handleCardDetails}>
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Details" />
        </MenuItem>
        <MenuItem onClick={handleEditCard}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Card" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Visitor;
